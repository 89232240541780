import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './/assets/style/main.css';
//import './/assets/style/pb8.css';
import './/assets/style/b8.css';

const app = createApp(App).use(store).use(router);
//app.config.globalProperties.host = 'http://localhost/pb8b';
app.config.globalProperties.host = 'https://db.b8.wtf/apiTrain/';

app.mount('#app');